import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form } from 'react-bootstrap';
import { useState } from 'react';
import { BiMailSend } from 'react-icons/bi';
import { init, send } from 'emailjs-com';

function App() {
    const userID = process.env.REACT_APP_USER_ID;
    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const templateID = process.env.REACT_APP_TEMPLATE_ID;

    const [subject,setSubject] = useState('');
    const [company,setCompany] = useState('');
    const [department,setDepartment] = useState('');
    const [person,setPerson] = useState('');
    const [email,setEmail] = useState('');
    const [content,setContent] = useState('');

    const [isLoading, setLoading] = useState(false);

    const Form_Subject = (event) => {
        setSubject(event.target.value)
    }
    const Form_Company = (event) => {
        setCompany(event.target.value)
    }
    const Form_Department = (event) => {
        setDepartment(event.target.value)
    }
    const Form_Person = (event) => {
        setPerson(event.target.value)
    }
    const Form_Email = (event) => {
        setEmail(event.target.value)
    }
    const Form_Content = (event) => {
        setContent(event.target.value)
    }

    const simulateNetworkRequest = () => {
        return new Promise((resolve) => setTimeout(resolve, 3000));
    }


    const Send_Email = () => {

	setLoading(true)

	if (
            userID !== undefined &&
            serviceID !== undefined &&
	    templateID !== undefined
	) {
	    init(userID);
	    const template_param = {
		subject: subject,
	        company: company,
		department: department,
		person: person,
		email: email,
		content: content,
	    };

	    send(serviceID, templateID, template_param).then(() => {
    	        window.alert('お問い合わせを送信致しました。');
    	        setSubject('');
        	setCompany('');
        	setDepartment('');
        	setPerson('');
        	setEmail('');
        	setContent('');
    	    });
	}

	simulateNetworkRequest().then(() => {
            setLoading(false);
	});

    }

    const spaceCheck = 
        subject === '' || company === '' || department === '' || person === '' || email === '' || content === '';
    const subjectCheck = subject === '';
    const companyCheck = company === '';
    const departmentCheck = department === '';
    const personCheck = person === '';
    const emailCheck = email === '';
    const contentCheck = content === '';


    return (
	<>
	<div className="oreno-font">
	    <h1>MANUFACIA サポートフォーム</h1>
	</div>

	<div className="oreno-form">
	    {companyCheck ? <span style={{ color: 'red', fontSize: 14 }}> *必須</span> : ''}
            <Form.Control value={company} onChange={Form_Company} className="" size="lg" type="text" placeholder="会社名" />
	</div>

	<div className="oreno-form">
	    {departmentCheck ? <span style={{ color: 'red', fontSize: 14 }}> *必須</span> : ''}
            <Form.Control value={department} onChange={Form_Department} className="" size="lg" type="text" placeholder="部署" />
	</div>

	<div className="oreno-form">
	    {personCheck ? <span style={{ color: 'red', fontSize: 14 }}> *必須</span> : ''}
            <Form.Control value={person} onChange={Form_Person} className="" size="lg" type="text" placeholder="氏名" />
	</div>

	<div className="oreno-form">
	    {emailCheck ? <span style={{ color: 'red', fontSize: 14 }}> *必須</span> : ''}
            <Form.Control value={email} onChange={Form_Email} className="" size="lg" type="text" placeholder="連絡先メールアドレス" />
	</div>

	<div className="oreno-form">
	    {subjectCheck ? <span style={{ color: 'red', fontSize: 14 }}> *必須</span> : ''}
            <Form.Control value={subject} onChange={Form_Subject} className="" size="lg" type="text" placeholder="件名" />
	</div>

	<div className="oreno-form">
	    {contentCheck ? <span style={{ color: 'red', fontSize: 14 }}> *必須</span> : ''}
   	    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
	        <Form.Label className="oreno-font-3">質問内容</Form.Label>
     	        <Form.Control value={content} as="textarea" onChange={Form_Content} placeholder="" rows={6} />
	    </Form.Group>
	</div>

	     
	<div className="oreno-form-2">
	    {spaceCheck ? <p style={{ color: 'red', fontSize: 14 }}> *必須項目を全て埋めてください</p> : ''}
	    <Button disabled={isLoading |spaceCheck } onClick={Send_Email} variant="outline-success">{isLoading ? '送信完了' : 'メールを送信する'} <BiMailSend size="2em"/></Button>
	</div>

	</>
  );
}

export default App;
